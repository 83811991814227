<template>
  <div class="product" @click.stop="close">
    <div style=" position: relative;z-index:99" class='progressive'>
      <img :src="banner.preview" alt style="width:100%" />
      <!-- v-progressive='banner.preview' -->
    </div>
    <div class="bg1" style="margin-top:-17px">
      <div class="total">
        <div class="title">与快进商店合作，门店智能变身，年营业额增加10万+</div>
        <div
          class="text1"
        >快进商店通过对合作门店进行升级改造，采用智能硬件+客服远程值守+人工智能视觉风控的组合方案，在不改变原有经营模式下，即让门店实现24小时营业、消费者自助购物。</div>
        <div
          class="text2"
        >与快进商店合作后，不论白天还是夜间，不论外出送货还是临时有事，店主均可随时离店，使用管理App一键开启值守，门店即可变身智能便利店，实现不间断营业。用科技的手段降本增收，轻松开店。</div>
      </div>
      
      <div ref="start" class="starts">
        <!-- <img src="../assets/product/center.png" alt v-show="isShow==0" /> -->
        <img src="../assets/product/end.gif" alt />
        <!-- <img src="../assets/product/start.gif" alt v-show="isShow==1" /> -->
      </div>
     
    </div>
    
    <div class="ViodeDesc">
        <div class="img-box">
            <div class="item-box" v-for="(item,index) in imgdescList" :key="index">
                <img class="img1" :src="item.src">
                <img class="img2" :src="item.activeSrc">
            </div>
        </div>
        <div class="video-box">
            <div class="title-box">
                 <div
                    v-for="(item, index) in titleList"
                    :key="item.name"
                    @click="changeTitle(index,item)"
                    class="navItem"
                  >
                    <div class="name" :style="{color: index === titleActive?'#1F10E0':'#C0C0FF'}">{{ item.name }}</div>
                    <div
                      class="line"
                      :class="{ 'nav-list--active': index === titleActive, }"
                    ></div>
                    <div class="line" ></div>
                  </div>
            </div>
            <div class="gradient"></div>
            <div class="video">
                <div class="iconPlay" @click="videoplay" v-show="isPlay">
                  <img src="@/assets/product/play.svg">
                </div>
                <div class="videoBg" v-show="isPlay">
                    <img src="@/assets/product/voideBg1.jpg" v-if="titleActive == 0">
                    <img src="@/assets/product/voideBg2.jpg" v-if="titleActive == 1">
                </div>
                <video
                  style="width:100%;height:100%;object-fit: fill;border-radius: .3125rem;"
                  ref="myvideo"  
                  :controls="!isPlay"   
                  :webkit-playsinline="true"
                  :playsinline="true"    
                  src="https://cdn.ikuaijin.com/kj/www/seq_06020409.mp4"                           
                >
              </video>
            </div>
        </div>
        <div class="btn" @click.stop="openDialog">合作咨询</div>
    </div>

    <div ref="chart" class="chartbg">
      <!-- <img src="../assets/product/startChart.jpg" alt style="width:100%" v-show="isShow1==1" />
      <img src="../assets/product/chart.jpg" alt style="width:100%" v-show="isShow1==3" />
      <img src="../assets/product/chart.gif" alt style="width:100%" v-show="isShow1==2" />-->
      <div class="box">
        <div class="left" :class="{'actLeft':isShow1==2||isShow1==3}" v-show="isShow1==2||isShow1==3">
          <div >
            根据中国连锁经营协会权威数据，运营成熟的24小时便利店夜间+凌晨时段收入占比达整体的近<span style="font-weight:700">20%</span>。
          </div>
          <div style="margin-top:1.2rem;margin-bottom:2.7rem" >
            以门店日销3000元计算，相当于该时段每年贡献<span style="font-weight:700">超20万</span>营业额。
          </div>
          <div class="line"></div>
          <div style="margin-top:2.7rem">不是夜间没有顾客，不是顾客想多花费数元的配送费点外卖，而是目前消费者的24小时购物需求无法被满足，更没有被挖掘！</div>
        </div>
        <div class="right">
          <div class="shopName">
            <div v-show="isShow1==3">
              <span class="tip"></span> 便利店不同时段收入占比
            </div>
          </div>
          <div class="rightImg">
            <div class="time1" v-show="isShow1==3">24:00-06:00</div>
            <div class="time2" v-show="isShow1==3">06:00-12:00</div>
            <div class="time3" v-show="isShow1==3">12:00-18:00</div>
            <div class="time4" v-show="isShow1==3">18:00-24:00</div>
            <img src="../assets/product/chart.png" alt v-show="isShow1==3" />
            <img src="../assets/product/chartgif.gif" alt v-show="isShow1==2" />
          </div>
        </div>
      </div>
      <div class="chartTab">
        <div class="msg1">目前夜间不营业</div>
        <div class="msg2">目前夜间有营业</div>
        <div class="msg3">升级前</div>
        <div class="msg4">损失夜间营业额</div>
        <div class="msg5">需雇店员或家人轮班</div>
        <div class="msg6">升级后</div>
        <div class="msg7">24小时营业年增收近20%</div>
        <div class="msg8">
          <div>仅店老板一人</div>即可实现全天营业
        </div>
        <div class="msg9">升级后预计年增收</div>
        <div class="msg10">
          <div>日均营业额增加500元</div>年增收18万,利润4.5万
        </div>
        <div class="msg11">
          <div>店员月薪4000</div>年降低成本近5万元
        </div>
        <img src="../assets/product/chartTab.png" alt style="width:72.5%" />
      </div>
    </div>
    <div class="types types1" v-show="type==0" style="font-size:0">
      <img src="../assets/product/type1.png" alt class="bgType" style="display：block" />
      <div class="typeTitle">不论夫妻店、连锁便利店，还是快消品厂家，快进商店为您全面赋能</div>
      <div class="typeBox">
        <div class="typeList">
          <div
            v-for="(item,index) in list1"
            :key="item "
            class="typeItem"
            :class="{'activeType':index==actIndex}"
            @mouseover="chooseType(index)"
          >{{item}}</div>
        </div>
        <div style="width:39.75rem">
          <div v-for="item in type1" :key="item.name" class="redbox">
            <div class="red"></div>
            <div>
              <div class="typeName">{{item.name}}</div>
              <div class="typeText">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn" @click.stop="openDialog">合作咨询</div>
    </div>
    <div class="types types2" v-show="type==1" style="font-size:0">
      <img src="../assets/product/type2.png" alt class="bgType" style="display：block" />
      <div class="typeTitle">不论夫妻店、连锁便利店，还是快消品厂家，快进商店为您全面赋能</div>
      <div class="typeBox">
        <div class="typeList">
          <div
            v-for="(item,index) in list1"
            :key="item "
            class="typeItem"
            :class="{'activeType':index==actIndex}"
            @mouseover="chooseType(index)"
          >{{item}}</div>
        </div>
        <div style="width:39.75rem">
          <div v-for="item in type2" :key="item.name" class="redbox">
            <div class="red"></div>
            <div>
              <div class="typeName">{{item.name}}</div>
              <div class="typeText">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn" @click.stop="openDialog">合作咨询</div>
    </div>
    <div class="types types3" v-show="type==2" style="font-size:0">
      <img src="../assets/product/type3.png" alt class="bgType" style="display：block" />
      <div class="typeTitle">不论夫妻店、连锁便利店，还是快消品厂家，快进商店为您全面赋能</div>
      <div class="typeBox">
        <div class="typeList">
          <div
            v-for="(item,index) in list1"
            :key="item "
            class="typeItem"
            :class="{'activeType':index==actIndex}"
            @mouseover="chooseType(index)"
          >{{item}}</div>
        </div>
        <div style="width:39.75rem">
          <div v-for="item in type3" :key="item.name" class="redbox">
            <div class="red"></div>
            <div>
              <div class="typeName">{{item.name}}</div>
              <div class="typeText">{{item.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn" @click.stop="openDialog">合作咨询</div>
    </div>
    <div class="bg2">
      <div style="width:100%;height:1px"></div>
      <div class="bgTitle">
        <div>
          <img src="../assets/home/left.png" alt />
        </div>
        <div style="margin:0.5rem 1.25rem 0">合作流程</div>
        <div>
          <img src="../assets/home/right.png" alt />
        </div>
      </div>
      <div class="itemList">
        <div v-for="item in list" :key="item.name" class="itemIcons">
          <div v-if="item.name">
            <div>
              <img :src="item.url" alt @mouseover="mouseOver(item)" @mouseleave="mouseLeave(item)" />
            </div>
            <div class="name">{{item.name}}</div>
            <div class="text">{{item.text}}</div>
            <div class="text">{{item.text1}}</div>
          </div>
          <div v-else>
            <img
              src="../assets/product/arrow.png"
              alt
              style="width:1.875rem;height:1.875rem;margin-top:9.0625rem"
            />
          </div>
        </div>
      </div>
    </div>
    
    <div class="AutoShow">
       <div class="titles">已合作门店升级案例</div>
        <!-- 滑动 -->
        <div class="AutoSlidelist">
          <div class="shadow"></div>
          <div class="shadow2"></div>
          <AutoSlide  :list="[1,22]"  ref="AutoSlide1" :speed="10" :equipmentType="'pc'" :colortype="1"/>
          <AutoSlide  :list="[23,45]" ref="AutoSlide2" :speed="10" :equipmentType="'pc'" :colortype="2"/>
        </div>
        <div class="text-box">
           <div>每家传统零售店都值得通过快进商店的智能升级，降低人工成本，成为24小时店</div>
           <div>不开门营业的每一分钟都是在损失营业额，浪费房租</div>
        </div>
        <div class="endText">
            别再犹豫，改变就是现在
        </div>
     </div>

    <gobalFooter></gobalFooter>
    <dialogConsult
      :show="show"
      :title="title"
      @hideModal="hideModal"
      @submit="submit"
      @stopMp="stopMp"
    ></dialogConsult>
  </div>
</template>
<script>
import dialogConsult from "../components/dialogConsult";
import gobalFooter from "../components/gobalFooter";
import AutoSlide from "../components/AutoSlide.vue"
export default {
  components: { gobalFooter, dialogConsult ,AutoSlide},
  data() {
    return {
      banner:
        {src:require("../assets/product/banner.png"),
         preview:require("../assets/product/banner.svg")},

      type: 0,
      title: "合作咨询",
      show: false,
      typeSrc: require("../assets/product/type1.png"),
      typeSrc2: require("../assets/product/type2.png"),
      typeSrc3: require("../assets/product/type3.png"),
      isShow: 0,
      isShow1: 1,
      actIndex: 0,
      typeUrl: require("../assets/product/typeText1.png"),
      typeUrl2: require("../assets/product/typeText2.png"),
      typeUrl3: require("../assets/product/typeText3.png"),
      shopUrl: require("../assets/product/end.gif"),

      indexs: 1,
      index1: 1,

      

      list: [
        {
          name: "项目咨询",
          text: "通过官网留言或",
          text1: "致电客服进行咨询",
          url: require("../assets/product/icon1.png"),
          imgUrl: require("../assets/product/icon1.png"),
          gifUrl: require("../assets/product/icon1.gif")
        },
        {
          imgUrl: require("../assets/product/arrow.png")
        },
        {
          name: "签约洽谈",
          text: "业务顾问一对一洽",
          text1: "谈签订合作协议",
          url: require("../assets/product/icon2.png"),
          imgUrl: require("../assets/product/icon2.png"),
          gifUrl: require("../assets/product/icon2.gif")
        },
        {
          imgUrl: require("../assets/product/arrow.png")
        },
        {
          name: "方案设计",
          text: "工程人员针对合作",
          text1: "门店进行勘察测量",
          url: require("../assets/product/icon3.png"),
          imgUrl: require("../assets/product/icon3.png"),
          gifUrl: require("../assets/product/icon3.gif")
        },
        {
          imgUrl: require("../assets/product/arrow.png")
        },
        {
          name: "改造施工",
          text: "进行门店改造完成，",
          text1: "设备安装部署",
          url: require("../assets/product/icon4.png"),
          imgUrl: require("../assets/product/icon4.png"),
          gifUrl: require("../assets/product/icon4.gif")
        },
        {
          imgUrl: require("../assets/product/arrow.png")
        },
        {
          name: "系统培训",
          text: "专人培训，后台简单",
          text1: "易用无操作门槛",
          url: require("../assets/product/icon5.png"),
          imgUrl: require("../assets/product/icon5.png"),
          gifUrl: require("../assets/product/icon5.gif")
        },
        {
          imgUrl: require("../assets/product/arrow.png")
        },
        {
          name: "正式上线",
          text: "升级完成，开启您的",
          text1: "智能门店事业",
          url: require("../assets/product/icon6.png"),
          imgUrl: require("../assets/product/icon6.png"),
          gifUrl: require("../assets/product/icon6.gif")
        }
      ],
      list1: ["零售店主", "连锁企业", "快消品牌/经销商"],
      type1: [
        {
          name: "全年24小时持续营业",
          text: "升级改造实现24小时营业，提升营业额，不错过每一位顾客"
        },
        {
          name: "有人/无人自由切换",
          text: "实现店主时间自由，经营模式一键切换，想走就走"
        },
        {
          name: "降低门店经营成本",
          text: "智能值守替代人工，降低成本，每年节省数万元"
        },
        {
          name: "平台多项智能防盗保障",
          text: "360度无死角监控+人工客服监管+Ai智能视觉风控，多重保障商品安全"
        }
      ],
      type2: [
        {
          name: "提升门店用户体验",
          text: "所有门店实现24小时营业及自助收银服务，大幅提升用户体验"
        },
        {
          name: "完善品牌加盟方案",
          text: "一体化智能方案输出，连锁加盟店科技含量倍增"
        },
        {
          name: "数字化升级高效运营",
          text: "打通营业数据、会员管理等多个节点，总部运营管理更高效"
        },
        {
          name: "支持系统级数据对接",
          text: "快进商店强大技术实力保障，数据对接告别跨系统繁琐操作"
        }
      ],
      type3: [
        {
          name: "渠道直连快速铺市",
          text: "产品直达快进商店门店网络，高效铺市实现曝光，提高线下渠道渗透率"
        },
        {
          name: "营销活动精准触达",
          text: "消费者小程序扫码进店购物，选购+支付动作二合一精准下达营销活动"
        },
        {
          name: "用户画像有效沉淀",
          text:
            "社区店业态离消费者最近，帮助品牌新品落地等关键动作，打造数字运营阵地"
        },
        {
          name: "产品动销清晰掌握",
          text: "100%真实数据可靠反馈，无中间环节，时效性、准确度极大提升"
        }
      ],
      imgdescList:[
        {
          src:require('@/assets/product/product-01.svg'),
          activeSrc:require('@/assets/product/productActive-1.svg')
        },
        {
          src:require('@/assets/product/product-02.svg'),
          activeSrc:require('@/assets/product/productActive-2.svg')
        },
        {
          src:require('@/assets/product/product-03.svg'),
          activeSrc:require('@/assets/product/productActive-3.svg')
        },
        {
          src:require('@/assets/product/product-04.svg'),
          activeSrc:require('@/assets/product/productActive-4.svg')
        }
      ],
      titleActive:0,
      titleList:[
          { name: "远程值守演示" },
          { name: "合作店主访谈"},
         
      ],
      isPlay:true,

    };
  },
  mounted() {
    this.isElementInViewport(this.$refs.start);
    this.isElementInViewports(this.$refs.chart);
    
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.chartUrl = require("../assets/product/chart.jpg");
    //   }, 2000);
    // });
  },
  destroyed() {
    //离开这个界面之后，删除，不然会有问题
  },
  methods: {
    hideModal() {
      // 取消弹窗回调
      this.show = false;
    },

    submit() {
      // 确认弹窗回调
      this.show = false;
    },
    close() {
      //  document.body.parentNode.style.overflow="scroll";//隐藏且禁用
      this.show = false;
    },
    stopMp() {
      this.show = true;
    },
    openDialog() {
      //  document.body.parentNode.style.overflow="hidden";//隐藏且禁用
      this.show = true;
    },
    isElementInViewport(el) {
      let that = this;
      document.addEventListener("scroll", () => {
        let rect = el.getBoundingClientRect();
        
        if (rect.top < 750 && that.indexs == 1) {
          that.indexs = 2;
          that.isShow = 1;

          setTimeout(() => {
            document.removeEventListener("scroll", () => {});
            that.isShow = 2;
          }, 3600);
        }
       
      });
    },
    isElementInViewports(el) {
      let that = this;
      document.addEventListener("scroll", () => {
        let rect = el.getBoundingClientRect();
      

        if (rect.top < 600 && that.index1 == 1) {
          this.isShow1 = 2;
          that.index1 = 2;

          setTimeout(() => {
            document.removeEventListener("scroll", () => {});
            this.isShow1 = 3;
          }, 1000);
        }
      });
    },
    
    mouseOver(item) {
      item.url = item.gifUrl;

      setTimeout(() => {
        item.url = item.imgUrl;
      }, 1100);
    },
    mouseLeave(item) {
      // item.url = item.imgUrl;
    },
    chooseType(index) {
      this.actIndex = index;
      this.type = index;
      
    },
    changeTitle(index,item){
      this.titleActive = index;
      let arr = ['https://cdn.ikuaijin.com/kj/www/seq_06020409.mp4','https://cdn.ikuaijin.com/kj/www/seq_03.mp4'];
      this.$refs['myvideo'].src = arr[index];
      this.isPlay = true;
    },
    videoplay(){
      this.isPlay = false;
      this.$refs['myvideo'].play();
      this.$refs['myvideo'].onended = () => {
         this.isPlay = true;
      }
      
    }
    
  }
};
</script>
<style lang="scss" scoped>
.types1 {
  // background: url("../assets/product/type1.png") no-repeat;
}
.types2 {
  background: url("../assets/product/type2.png") no-repeat;
}
.types3 {
  background: url("../assets/product/type3.png") no-repeat;
}
@keyframes change {
  from {
    position: absolute;
    top: 8.5rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
  to {
    position: absolute;
    top: 7rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
@keyframes change1 {
  from {
    margin-top: 8rem;
  }
  to {
    margin-top: 5rem;
  }
}
@keyframes changeLength {
    from {
      width: 0px;
    }
    to {
       width: 8.0625rem;;
    }
  }
  @keyframes overChange {
    from {
      width: 8.0625rem;
    }
    to {
      width: 0px;
    }
  }



.product {
  width: 100%;
  font-family: opposr,sans-serif;
  margin-top: 68px;
}

.bg1 {
  width: 100%;
  height: 80rem;
  background: url("../assets/product/bg1.png") no-repeat;
  // background-size: cover;
  position: relative;
  background-size: 100% auto;
  text-align: center;
  .total {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    animation: change 1s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  .title {
    font-size: 1.6875rem;
    color: #3b3b72;
    letter-spacing: 2px;
    font-family: opposm,sans-serif;
  }
  .text1 {

    line-height: 2.1875rem;
    text-align: left;
    margin: 50px auto 0;
    width: 62.5rem;

    color: #4e4d5e;
    font-size: 1.25rem;
    letter-spacing: 1px;
  }
  .text2 {
    line-height: 2.1875rem;
    text-align: left;
    margin: 20px auto 0;
    width: 62.5rem;

    color: #4e4d5e;
    font-size: 1.25rem;
    letter-spacing: 1px;
  }
  .starts {
    text-align: center;
    position: absolute;
    bottom: 4.5rem;
    left: 50%;
    transform: translate(-53%, 0);
    img {
      width: 75rem;
      height: 48.75rem;
    }
  }
  .btn {
    width: 11.25rem;
    height: 3.125rem;
    line-height: 3.125rem;
    background: #1f10e0;
    font-size: 1.375rem;

    font-weight: 400;
    color: #ffffff;
    border-radius: 2px;
    position: absolute;
    bottom: 3.125rem;
    left: 50%;

    transform: translate(-50%, 0);
    text-align: center;
    font-family: opposm,sans-serif;
    letter-spacing: 2px;
    cursor: pointer;
  }
  .btn:hover {
    background-color: #4234fa;
  }
}
.chartbg {
  margin-top: 12rem;
  width: 100%;
  height: 71rem;
  background: url("../assets/product/chartbg.png") no-repeat;
  background-size: 100% 100%;

  .box {
    display: flex;
    width: 70%;
    height: 30rem;
    justify-content: space-around;
    margin: 0 auto;
    position: relative;
  }
  .actLeft {
    animation: change1 0.5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
  .left {
    letter-spacing: 2px;
    margin-top: 8rem;
    font-size: 1.25rem;
    font-weight: 400;
    color: #4e4d5e;
    width: 35%;
    height: 100%;
    line-height: 2rem;
    letter-spacing: 1px;
    text-align: justify;

    .line {
      position: absolute;
      top: 17.5rem;
      width:35%;
      height: 1px;
      background-color: #cccccc;
      // margin: 3rem 0;
    }
  }
  .right {
    width: 35.4375rem;
    margin-top: 4rem;
    // margin-left: 10rem;
    .shopName {
      width: 100%;
      font-size: 1.3rem;
      height: 3rem;
      font-weight: 500;
      color: #4e4d5e;
      line-height: 3.9375rem;
      margin-left: 11%;
      .tip {
        width: 3px;
        height: 16px;
        background-color: #ff3162;
        display: inline-block;
      }
    }
    .rightImg {
      font-size: 1.1875rem;
      font-weight: 500;
      color: #4e4d5e;
      position: relative;
      .time1 {
        position: absolute;
        right: 0%;
        top: 12.5%;
      }
      .time2 {
        position: absolute;
        right: 0%;
        top: 24.5%;
      }
      .time3 {
        position: absolute;
        right: 0%;
        top: 51.5%;
      }
      .time4 {
        position: absolute;
        right: 0%;
        top: 72.5%;
      }
    }
    img {
      width: 31.4375rem;
      height: 22.1875rem;
    }
  }
  .chartTab {
    text-align: center;
    margin: 1rem auto 0;
    letter-spacing: 1px;
    position: relative;
    .msg1 {
      font-size: 1.7rem;

      font-weight: bold;
      color: #4e4d5e;
      position: absolute;
      top: 9%;
      left: 43%;
      font-family: opposm,sans-serif;
    }
    .msg2 {
      font-size: 1.7rem;
      font-weight: bold;
      color: #4e4d5e;

      position: absolute;
      top: 9%;
      left: 67%;
      font-family: opposm,sans-serif;
    }
    .msg3 {
      font-size: 1.7rem;
      font-weight: bold;
      color: #4e4d5e;
      position: absolute;
      top: 30%;
      left: 25%;
      font-family: opposm,sans-serif;
    }
    .msg4 {
      font-size: 1.5rem;

      font-weight: 400;
      color: #333333;
      position: absolute;
      top: 30.5%;
      left: 43.6%;
    }
    .msg5 {
      font-size: 1.5rem;

      font-weight: 400;
      color: #333333;
      position: absolute;
      top: 30.5%;
      left: 66.5%;
    }

    .msg6 {
      font-size: 1.7rem;
      font-weight: bold;
      color: #ffffff;
      position: absolute;
      top: 55.5%;
      left: 25%;
      font-family: opposm,sans-serif;
    }
    .msg7 {
      font-size: 1.5rem;

      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: 56.2%;
      left: 41%;
    }
    .msg8 {
      text-align: center;
      font-size: 1.5rem;
      line-height: 2.1rem;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: 54%;
      left: 67%;
    }
    .msg9 {
      font-size: 1.7rem;
      font-weight: bold;
      color: #ffffff;
      position: absolute;
      top: 80%;
      left: 21%;
      font-family: opposm,sans-serif;
    }
    .msg10 {
      position: absolute;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: 78%;
      left: 41.8%;
    }
    .msg11 {
      text-align: center;
      position: absolute;
      font-size: 1.5rem;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: 78%;
      left: 66.5%;
    }
  }
}
.types {
  width: 100%;
  height: 43.75rem;

  position: relative;
  background-size: 100% 100%;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .typeTitle {
    position: absolute;
    top: 4.3125rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1.6875rem;

    font-weight: 500;
    color: #ffffff;
    letter-spacing: 2px;
    font-family: opposm,sans-serif;
  }
  // img {
  //   width: 45rem;
  //   height: 26.75rem;
  // }
  .redbox {
    display: flex;
    margin-bottom: 2rem;
  }
  .redbox:last-child {
    margin-bottom: 0rem !important;
  }
  .red {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: #ff3162;
    margin-top: 0.825rem;
    margin-right: 0.625rem;
  }
  .typeName {
    letter-spacing: 2px;
    font-size: 1.4rem;

    font-weight: 500;
    color: #ffffff;
    line-height: 2.375rem;
  }
  .typeText {
    font-size: 0.9rem;
    letter-spacing: 2px;

    font-weight: 400;
    color: #ffffff;
    line-height: 1.2rem;
  }
  .typeBox {
    position: absolute;
    bottom: 12rem;
    width: 60%;

    display: flex;
    // justify-content: space-between;
    left: 50%;
    transform: translate(-50%, 0);
    .typeList {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-right: 9rem;
    }
    .typeItem {
      width: 21.25rem;
      height: 3.75rem;
      font-size: 1.375rem;

      font-weight: 400;
      color: #ffffff;
      line-height: 3.75rem;
      text-align: center;
      cursor: pointer;
      letter-spacing: 2px;
    }
    .activeType {
      width: 21.25rem;
      height: 3.75rem;
      // background: rgba(0, 0, 0, 0.3);
      font-size: 1.5625rem;
      background: url("../assets/product/textbg.png") no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      line-height: 3.75rem;
      text-align: center;
      font-family: opposm,sans-serif;
    }
  }
  .btn {
    text-align: center;
    width: 11.25rem;
    height: 3.125rem;
    line-height: 3.125rem;
    background: #ffffff;
    font-size: 1.375rem;

    font-weight: 400;
    color: #1f10e0;
    border-radius: 2px;
    position: absolute;
    bottom: 4.375rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: opposm,sans-serif;
    letter-spacing: 2px;
    cursor: pointer;
  }
  .btn:hover {
    background-color: #eeeeff;
  }
}
.bg2 {
  width: 100%;
  height: 31.25rem;
  // background: url("../assets/product/bg2.png") no-repeat;
  // background-size: cover;
  position: relative;
  // background-size: 100% auto;
  // display: flex;
  // justify-content: center;
  .bgTitle {
    display: flex;
    font-size: 1.75rem;
    justify-content: center;
    font-weight: 550;
    color: #3b3b72;
    margin-top: 5.625rem;
    letter-spacing: 2px;
    font-family: opposm,sans-serif;
    img {
      width: 28.875rem;
      height: 3px;
    }
  }
  .itemList {
    width: 75%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 6rem;
    img {
      width: 7.5rem;
      height: 7.5rem;
    }
  }

  .itemIcons {
    text-align: center;
    display: flex;
    justify-content: space-around;
    .name {
      margin: 0.5rem auto 0;
      width: 9.375rem;
      border-bottom: 1px solid #3b3b72;
      letter-spacing: 3px;
      font-family: opposm,sans-serif;
      color: #4e4d5e;
      font-size: 1.25rem;
      font-weight: 550;
      padding-bottom: 0.625rem;
    }
    .text {
      margin-top: 0.2rem;
      letter-spacing: 3px;
      font-family: opposr,sans-serif;
      color: #4e4d5e;
      font-size: 0.875rem;
    }
  }
}

.ViodeDesc {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5.875rem   auto 5.875rem;
  width: 100%;
  .img-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:32.1875rem;
    height: 31.5625rem;
    .item-box {
      position: relative;
      margin-bottom: .9375rem;
      height: 15.3125rem;
      width: 15.625rem;
      border-radius: .3125rem;
      background: #EEEEFF;
      &:hover .img2{
        display: inline-block;
      } 
      .img1 {
        width: 100%;
        height: 100%;
      }
      .img2 {
        display: none;
        position:absolute;
        left: 0px;
        top: 0px;
        z-index: 999;
        width: 100%;
        height: 100%;
      }
    }
  }
  .video-box {
    margin-left: 3.3125rem;
    width: 45.9375rem;
    height: 31.5625rem;
    background: #EEEEFF;
    border-radius: .3125rem;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 3.9375rem;
      border-bottom: 1px solid #C0C0FF;
      .navItem {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .name {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 1.3125rem;
        font-family: opposm;
        font-weight: 500;
      }
      .line {
        display: inline;
        transform: rotate(180deg) !important;
      }
      .nav-list--active {
        position: absolute;
        bottom: -0.0625rem;
        width: 0px;
        height: .125rem;
        background-color: #1F10E0;
        /*调用动画*/
        animation: changeLength 0.5s 1 alternate;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
      }
      .last-nav-list--active {
        position: absolute;
        bottom: -0.0625rem;
        width: 8.0625rem;
        height: .125rem;
        background-color: #1F10E0;
        /*调用动画*/
        animation: overChange 0.5s;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
      }
    }
    .gradient {
      width: 100%;
      height: 1.5rem;
      background: linear-gradient(0deg, #EEEEFF 0%, #DCDCF6 100%);
    }
    .video {
      position: relative;
      margin: 0px auto;
      margin-top: .3125rem;
      width: calc(100% - 1.375rem);
      height: 25.0688rem;
      .videoBg {
        position: absolute;
        left: 0px;
        top: 0px;
        width:100%;
        height: 100%;
        z-index: 2;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .iconPlay {
        position: absolute;
        top: calc((100% - 5.6875rem) / 2);
        left: calc((100% - 5.6875rem) / 2);
        width:5.6875rem;
        height: 5.6875rem;
        cursor: pointer;
        z-index: 3;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
      
  }
  .btn {
    width: 11.25rem;
    height: 3.125rem;
    line-height: 3.125rem;
    background: #1f10e0;
    font-size: 1.375rem;
    font-weight: 400;
    color: #ffffff;
    border-radius: 2px;
    position: absolute;
    bottom: -6.25rem;
    left: calc((100% - 11.25rem) / 2);
    text-align: center;
    font-family: opposm,sans-serif;
    letter-spacing: 2px;
    cursor: pointer;
  }
}

.AutoShow {
  width: 100%;
  height: 67.875rem;
  background: url("../assets/product/showBg.png") no-repeat;
  background-size: cover;
  .AutoSlidelist {
     position: relative;
     margin: 0px auto;
     width: 72%;
    .shadow {
        position:absolute;
        width: 6.25rem;
        height: 100%;
        z-index: 999;
        top: 0%;
        left: 0px;
        background: linear-gradient(to right, rgb(255,255,255) ,rgba(255,255,255,0));
    }   
    .shadow2 {
        position:absolute;
        width: 6.25rem;
        height:100%;
        z-index: 999;
        top: 0px;
        right: 0px;
        background: linear-gradient(to left, rgb(245,248,255) , rgba(245,248,255,0));
    }
  }
  .titles {
    padding-top: 6.4375rem;
    padding-bottom: 3.75rem ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto ;
    width: 22.5625rem;
    height: 2.4375rem;
    font-size: 2.5rem;
    font-family: opposm;
    font-weight: 500;
    color: #3B3B72;
  }
  .text-box {
    margin: 0px auto;
    margin-top: 5.1875rem;
    width: 60%;
    font-size: 2rem;
    font-family: opposm;
    font-weight: 500;
    color: #3B3B72;
    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:nth-child(1) {
        margin-bottom: .625rem;
      }
    }
  }
  .endText {
    text-align: center;
    margin: 0px auto;
    margin-top: 2.125rem;
    width: 28.125rem;
    height: 3.1875rem;
    line-height: 2.75rem;
    font-size: 2.5rem;
    font-family: opposb;
    color: #1F10E0;
    border-bottom: .375rem solid red;
  }
}
</style>
