<template>
  <div class="product">
    <img src="../../assets/mobile/product/banner.svg" alt style="width:100%" />
    <div v-if="isBaidu==1" class="productTitle">*投资有风险，加盟需谨慎</div>
    <div class="bg">
      <div class="bgtitle">与快进商店合作</div>
      <div class="bgtitle">门店智能变身</div>
      <div class="bgtitle">年营业额增加10万+</div>
      <div
        class="bgtext"
        style="margin-top:1.5rem"
      >快进商店通过对合作门店进行升级改造，采用智能硬件+客服远程值守+人工智能视觉风控的组合方案，在不改变原有经营模式下，即让门店实现24小时营业、消费者自助购物。</div>
      <div
        class="bgtext"
      >与快进商店合作后，不论白天还是夜间，不论外出送货还是临时有事，店主均可随时离店，使用管理App一键开启值守，门店即可变身智能便利店，实现不间断营业。用科技的手段降本增收，轻松开店。</div>
      <div ref="start" class="starts">
        <img src="../../assets/product/end.gif" alt style="width:90%" />
        <!-- <img src="../../assets/mobile/product/start.gif" alt v-show="isShow==1" style="width:90%" /> -->
      </div>
      
    </div>
    <div class="img4">
      <img src="@/assets/mobile/product/img4box.png" alt="">
      <!-- <div class="img-box">
        <img src="@/assets/mobile/product/product1.svg">
      </div>
      <div class="img-box">
        <img src="@/assets/mobile/product/product2.svg">
      </div>
      <div class="img-box">
        <img src="@/assets/mobile/product/product3.svg">
      </div>
      <div class="img-box">
         <img src="@/assets/mobile/product/product4.svg">
      </div>   -->
    </div>


     <div class="video-box">
            <div class="title-box">
                 <div
                    v-for="(item, index) in titleList"
                    :key="item.name"
                    @click="changeName(index,item)"
                    class="navItem"
                  >
                    <div class="name" :style="{color: index === titleActive?'#1F10E0':'#C0C0FF'}">{{ item.name }}</div>
                    <div
                      class="line2"
                      :class="{ 'nav-list--active2': index === titleActive, }"
                    ></div>
                    <div class="line2" ></div>
                  </div>
            </div>
            <div class="gradient"></div>
            <div class="video">
                <div class="iconPlay" @click="videoplay" v-show="isPlay">
                  <img src="@/assets/product/play.svg">
                </div>
                <div class="videoBg" v-show="isPlay">
                    <img src="@/assets/mobile/product/voideBg1.jpg" v-if="titleActive == 0">
                    <img src="@/assets/mobile/product/voideBg2.jpg" v-if="titleActive == 1">
                </div>
                <video
                  style="width:100%;height:100%;object-fit: fill;border-radius: .3125rem;"
                  ref="myvideo"
                  :controls="!isPlay" 
                  :src="fileUrl"                           
                >
                
              </video>
            </div>
     </div>

     <div class="submit2" @click="openDialog">合作咨询</div>

    <div class="bg1">
      <div style="width:100%;height:1px"></div>
      <div class="box">
        <div>根据中国连锁经营协会权威数据，运营成熟的24小时便利店夜间+凌晨时段收入占比达整体的近20%。</div>
        <div style="margin-top:0.8rem">以门店日销3000元计算，相当于该时段每年贡献超20万营业额。</div>
        <div class="title">
          <span class="tips"></span>
          <span>便利店不同时段收入占比</span>
        </div>
        <div class="chart" ref="chart">
          <div class="time1" v-show="isShow1==3">24:00-06:00</div>
          <div class="time2" v-show="isShow1==3">06:00-12:00</div>
          <div class="time3" v-show="isShow1==3">12:00-18:00</div>
          <div class="time4" v-show="isShow1==3">18:00-24:00</div>
          <img src="../../assets/mobile/product/chart.png" alt v-show="isShow1==3" />
          <img src="../../assets/mobile/product/chart.gif" alt v-show="isShow1==2" />
        </div>
        <div class="text">不是夜间没有顾客，不是顾客想多花费数元的配送费点外卖，而是目前消费者的24小时购物需求无法被满足，更没有被挖掘！</div>
      </div>
      <div class="chartTab">
        <img src="../../assets/mobile/product/bg2.svg" alt style="width:100%;height:100%" />
      </div>
    </div>
    <div class="types" style="font-size:0">
      <img src="../../assets/mobile/product/type1.png" alt class="bgType" style="display：block" v-show='nowIndex==0'/>
      <img src="../../assets/mobile/product/type2.png" alt class="bgType" style="display：block"  v-show='nowIndex==1'/>
      <img src="../../assets/mobile/product/type3.png" alt class="bgType" style="display：block"  v-show='nowIndex==2'/>
      <div class="typeTitle">
        不论夫妻店、连锁便利店，还是快消品厂家
        <div>快进商店为您全面赋能</div>
      </div>
      
      <div class="typeBox">
        <div class="typeList">
          <div
            v-for="(item,index) in list1"
            :key="item "
            class="navItem"
            @click="changeTitle(index)"
            :class="{ 'bolds': index == nowIndex, }"
          >
            {{item}}
            <div class="line" :class="{ 'nav-list--active': index == nowIndex, }"></div>
            <!-- <div
              class="line"
              :class="{ 'last-nav-list--active':(index == actIndex) }"
            ></div>-->
          </div>
          <div class="allLine"></div>
        </div>
        <div style="margin-top:2rem">
          <swiper
      :options="swiperOption"
       @swiper="onSwiper"
      
      @slideChange="onSlideChange"
      ref="Swiper"
      
       
    >
     <swiper-slide v-for="(item,index) in type1" :key="index">
              <div v-for="items in item" :key="items.name" class="redbox">
                <div class="red">
                  <img
                    src="../../assets/mobile/product/red.png"
                    alt
                    style=" width: 0.4375rem !important;height: 0.4375rem !important;"
                  />
                </div>
                <div>
                  <div class="typeName">{{items.name}}</div>
                  <div class="typeText">{{items.text}}</div>
                </div>
              </div>
            </swiper-slide>
    </swiper>
        </div>
      </div>
      <div class="btn" @click.stop="openDialog">合作咨询</div>
    </div>
   

    <!-- <ul>
          <li @click="tabClick(0)" :class="{ actives: nowIndex === 0 }">
            tab1
          </li>
          <li @click="tabClick(1)" :class="{ actives: nowIndex === 1 }">
           tab2
          </li>
          <li @click="tabClick(2)" :class="{ actives: nowIndex === 2 }">
           tab3
          </li>     
</ul> -->

    <!-- <swiper
      :options="swiperOption"
       @swiper="onSwiper"
      :slides-per-view="4"
      @slideChange="onSlideChange"
      ref="Swiper"
      
       
    >
     <swiper-slide v-for="(item,index) in type1" :key="index">
              <div v-for="items in item" :key="items.name" class="redbox">
                <div class="red">
                  <img
                    src="../../assets/mobile/product/red.png"
                    alt
                    style=" width: 0.4375rem !important;height: 0.4375rem !important;"
                  />
                </div>
                <div>
                  <div class="typeName">{{items.name}}</div>
                  <div class="typeText">{{items.text}}</div>
                </div>
              </div>
            </swiper-slide>
    </swiper> -->

    <div class="bg2">
      <div style="width:100%;height:1px"></div>
      <div class="bgTitle">
        <div style="margin:0.5rem 1.25rem 0">合作流程</div>
      </div>
      <div class="itemList">
        <div
          v-for="(item,index) in list"
          :key="item.name"
          class="itemIcons"
          :class="{'short':index==1||index==3||index==6||index==8}"
        >
          <div v-if="item.name">
            <div>
              <img :src="item.url" alt />
            </div>
            <div class="name">{{item.name}}</div>
            <div class="text">{{item.text}}</div>
            <div class="text1">{{item.text1}}</div>
          </div>
          <div v-else>
            <img
              src="../../assets/mobile/product/arrow.png"
              alt
              style="width:.375rem;height: .625rem;margin-top:7rem"
            />
          </div>
        </div>
      </div>
    </div>

     <div class="AutoShow">
       <div class="titles">已合作门店升级案例</div>
        <!-- 滑动 -->
        <div class="AutoSlidelist">
          <div class="shadow"></div>
          <div class="shadow2"></div>
          <AutoSlide  :list="[1,22]"  ref="AutoSlide1" :speed="20" :equipmentType="'mobile'" :colortype="1"/>
          <AutoSlide  :list="[23,45]" ref="AutoSlide2" :speed="20" :equipmentType="'mobile'" :colortype="2"/>
        </div>
        <div class="text-box">
           <div>每家传统零售店都值得通过快进商店的智能升级</div>
           <div>降低人工成本，成为24小时店</div>
           <div>不开门营业的每一分钟都是在损失营业额，浪费房租</div>
        </div>
        <div class="endText">
            别再犹豫，改变就是现在
        </div>
     </div>

    <mGobalFooter></mGobalFooter>
     
  </div>
</template>
<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import mGobalFooter from "../../components/mobile/mGobalFooter";
import AutoSlide from "@/components/AutoSlide.vue"
const c_swiper = document.getElementsByClassName("swiper");

export default {
  components: {
    mGobalFooter,
    Swiper,
    SwiperSlide,
    AutoSlide
  },
  data() {
   let that=this
    return {
      fileUrl:'https://cdn.ikuaijin.com/kj/www/seq_06020409.mp4',
      nowIndex:0,
      isBaidu:0,
      swiperOption: {
        // 设定初始化时slide的索引
        initialSlide: 0,
        direction: "horizontal",
        // 自动切换图配置
        autoplay: {
          delay: 100000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        on: {
          slideChange() {
            //这个是当swiper被改变是的回调函数，可以拿到当前索引
            that.nowIndex = this.activeIndex;
          }
        }
      },

      active: 0,
      actIndex: 100,
      indexs: 1,
      isShow: 0,
      isShow1: 1,
      index1: 1,
      list: [
        {
          name: "项目咨询",
          text: "通过官网留言或",
          text1: "致电客服进行咨询",
          url: require("../../assets/mobile/product/icon1.svg")
        },
        {
          imgUrl: require("../../assets/mobile/product/arrow.png")
        },
        {
          name: "签约洽谈",
          text: "业务顾问一对一洽",
          text1: "谈签订合作协议",
          url: require("../../assets/mobile/product/icon2.svg")
        },
        {
          imgUrl: require("../../assets/mobile/product/arrow.png")
        },
        {
          name: "方案设计",
          text: "工程人员针对合作",
          text1: "门店进行勘察测量",
          url: require("../../assets/mobile/product/icon3.svg")
        },

        {
          name: "改造施工",
          text: "进行门店改造完成",
          text1: "设备安装部署",
          url: require("../../assets/mobile/product/icon4.svg")
        },
        {
          imgUrl: require("../../assets/mobile/product/arrow.png")
        },
        {
          name: "系统培训",
          text: "专人培训，后台简单",
          text1: "易用无操作门槛",
          url: require("../../assets/mobile/product/icon5.svg")
        },
        {
          imgUrl: require("../../assets/mobile/product/arrow.png")
        },
        {
          name: "正式上线",
          text: "升级完成，开启您的",
          text1: "智能门店事业",
          url: require("../../assets/mobile/product/icon6.svg")
        }
      ],
      list1: ["零售店主", "连锁企业", "快消品牌/经销商"],
      type1: [
        [
          {
            name: "全年24小时持续营业",
            text: "升级改造实现24小时营业，提升营业额，不错过每一位顾客"
          },
          {
            name: "有人/无人自由切换",
            text: "实现店主时间自由，经营模式一键切换，想走就走"
          },
          {
            name: "降低门店经营成本",
            text: "智能值守替代人工，降低成本，每年节省数万元"
          },
          {
            name: "平台多项智能防盗保障",
            text:
              "360度无死角监控+人工客服监管+Ai智能视觉风控，多重保障商品安全"
          }
        ],

        [
          {
            name: "提升门店用户体验",
            text: "所有门店实现24小时营业及自助收银服务，大幅提升用户体验"
          },
          {
            name: "完善品牌加盟方案",
            text: "一体化智能方案输出，连锁加盟店科技含量倍增"
          },
          {
            name: "数字化升级高效运营",
            text: "打通营业数据、会员管理等多个节点，总部运营管理更高效"
          },
          {
            name: "支持系统级数据对接",
            text: "快进商店强大技术实力保障，数据对接告别跨系统繁琐操作"
          }
        ],
        [
          {
            name: "渠道直连快速铺市",
            text:
              "产品直达快进商店门店网络，高效铺市实现曝光，提高线下渠道渗透率"
          },
          {
            name: "营销活动精准触达",
            text:
              "消费者小程序扫码进店购物，选购+支付动作二合一精准下达营销活动"
          },
          {
            name: "用户画像有效沉淀",
            text:
              "社区店业态离消费者最近，帮助品牌新品落地等关键动作，打造数字运营阵地"
          },
          {
            name: "产品动销清晰掌握",
            text: "100%真实数据可靠反馈，无中间环节，时效性、准确度极大提升"
          }
        ]
      ],
      titleActive:0,
      titleList:[
          { name: "远程值守演示" },
          { name: "合作店主访谈"},
         
      ],
      isPlay:true,
     
    };
  },
  watch: {
        // 实现监听this.$refs.Myswper.swiper.activeIndex发生变化执行赋值动作
        'this.$refs.Swiper.$swiper.activeIndex'(newval,old){

          // this.nowIndex=newval
        }
},
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  created(){
    this.isShow=0
      this.isShow1= 1
     this.isBaidu = window.localStorage.getItem('isBaidu')
  },
  mounted() {
    this.isElementInViewport(this.$refs.start);
    this.isElementInViewports(this.$refs.chart);
  },
  methods: {
    tabClick(index) {
      this.$refs.Swiper.$swiper.slideTo(index, 300, false);
      this.$refs.Swiper.swiperOptions.initialSlide = index;
      this.$refs.Swiper.initSwiper();
    },

    onSwiper(swiper) {
      // console.log(swiper);
    },
    onSlideChange() {
      // this.nowIndex=this.$refs.Swiper.$swiper.activeIndex
      // console.log( this.$refs.Swiper.$swiper.activeIndex);
    },
    isElementInViewport(el) {
      let that = this;
      document.addEventListener("scroll", () => {
        let rect = el.getBoundingClientRect();

        if (rect.top < 780 && that.indexs == 1) {
          that.indexs = 2;
          that.isShow = 1;

          setTimeout(() => {
            document.removeEventListener("scroll", () => {});
            that.isShow = 2;
          }, 3600);
        }
      });
    },
    isElementInViewports(el) {
      let that = this;
      document.addEventListener("scroll", () => {
        let rect = el.getBoundingClientRect();
        // console.log(rect.top);

        if (rect.top < 600 && that.index1 == 1) {
          this.isShow1 = 2;
          that.index1 = 2;

          setTimeout(() => {
            document.removeEventListener("scroll", () => {});
            this.isShow1 = 3;
          }, 1000);
        }
      });
    },
    openDialog() {
      this.$router.push("/mDialogConsult");
    },
    changeTitle(index) {
      // this.actIndex = this.active;

     this.nowIndex = index;

     
        // this.swiper.slideTo(index+1, 1000, true);
        this.$refs.Swiper.$swiper.slideTo(index, 300, true);
        // this.$refs.Swiper.swiperOptions.initialSlide = index;
        // this.$refs.Swiper.initSwiper();
     

      // this.$refs.mySwiper.slideNext()
    },
    changeName(index,item){
      this.titleActive = index;
      let arr = ['https://cdn.ikuaijin.com/kj/www/seq_06020409.mp4','https://cdn.ikuaijin.com/kj/www/seq_03.mp4'];
      this.fileUrl = arr[index];
      this.isPlay = true;
    },
    videoplay(){
      this.isPlay = false;
      this.$refs['myvideo'].play();
      this.$refs['myvideo'].onended = () => {
         this.isPlay = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.productTitle{
  position: fixed;
    top: 55px;
    right: 0;
    z-index: 9999;
    background: rgba(0,0,0,.3);
    color: #fff;
    padding: 3px 8px;
    font-size: 15px;
}
.actives{
  color:red
}
@keyframes changeLength {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }
}


@keyframes changeLength2 {
    from {
       width: 0px;
    }
    to {
       width: 5rem;
    }
}
@keyframes overChange {
  from {
    width: 5rem;
  }
  to {
    width: 0px;
  }
}



.line {
  display: inline;
  transform: rotate(180deg) !important;
}
.nav-list {
  display: flex;

  align-items: center;
}
.navItem {
  position: relative;
  cursor: pointer;
}
// .nav-list > div {
//   margin-left: 70px;
// }

.nav-list--active {
  position: absolute;
  top: 1.4825rem;
  left: 0;
  width: 0px;
  height: 3px;
  margin-top: 0px;
  background-color: #ffffff;
  /*调用动画*/
  animation: changeLength 0.5s 1 alternate;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.product {
  margin-top: 54px;
  font-family: opposr, sans-serif;
}
.bg {
  margin-top: -0.9375rem;
  padding-top: 1.875rem;
  background: url("../../assets/mobile/product/jmBg.png") no-repeat;
  background-size:cover;
  width: 100%;
  height: 38rem;
}
.bgtitle {
  margin-bottom: 0.1rem;
  text-align: center;
  font-size: 1.5rem;

  font-weight: 500;
  color: #3b3b72;
  font-family: opposm, sans-serif;
}
.bgtext {
  width: 82%;
  text-align: justify;
  letter-spacing: 1px;
  margin: 1rem auto 0;
  font-size: 0.93rem;

  font-weight: 400;
  color: #4e4d5e;
  line-height: 1.375rem;
}
.starts {
  height: 15.625rem;
  text-align: center;
  margin-top: 1.1rem;
  
}
.submit {
  margin: 0 auto 2.8125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  font-size: 0.9375rem;
  font-weight: 400;
  color: #ffffff;
  width: 7.5rem;
  height: 2.25rem;
  background: #1f10e0;
  border-radius: 3px;
}

.submit2 {
  margin: 0 auto 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  font-size: 0.9375rem;
  font-weight: 400;
  color: #ffffff;
  width: 7.5rem;
  height: 2.25rem;
  background: #1f10e0;
  border-radius: 3px;
}

.img4 {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // flex-wrap: wrap;
  margin:0px  auto;
  margin-top: 1.8125rem;
  width: calc(100% - 4rem);
  // height:19rem;
  text-align: center;

  img{
    width: 100%;
    height: 100%;
  }
  // .img-box {
  //   width: 9.2875rem;
  //   height: 9.25rem;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
}

.video-box {
    margin: 0.90rem auto 1.25rem;
    width: calc(100% - 4rem);
    height: 13.5rem;
    background: #EEEEFF;
    border-radius: .3125rem;
    .title-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 1.875rem;
      border-bottom: 1px solid #C0C0FF;
      .navItem {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .name {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: .85rem;
        font-family: opposm;
        font-weight: 500;
      }
      .line2 {
        display: inline;
        transform: rotate(180deg) !important;
      }
      .nav-list--active2 {
        position: absolute;
        bottom: 0px;
        left: calc((100% - 5rem) / 2 );
        width: 0px;
        height: .125rem;
        background-color: #1F10E0;
        /*调用动画*/
        animation: changeLength2 0.5s 1 alternate;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
      }
      .last-nav-list--active2 {
        position: absolute;
        bottom: 0px;
        left: calc((100% - 5rem) / 2 );
        width: 5rem;
        height: .125rem;
        background-color: #1F10E0;
        /*调用动画*/
        animation: overChange 0.5s;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
      }
    }
    .gradient {
      width: 100%;
      height: .6875rem;
      background: linear-gradient(0deg, #EEEEFF 0%, #DCDCF6 100%);
    }
    .iconPlay {
        position: absolute;
        top: calc((100% - 2.8125rem) / 2);
        left: calc((100% - 2.8125rem) / 2);
        width:2.8125rem;
        height: 2.8125rem;
        cursor: pointer;
        z-index: 999;
        img {
          width: 100%;
          height: 100%;
        }
    }
    .videoBg{
      position: absolute;
      left: 0px;
      top: 0px;
      width:100%;
      height: 100%;
      z-index: 888;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .video {
      position: relative;
      margin: 0px auto;
    
      width: calc(100% - .625rem);
      height: 10.625rem;
    }
}

.bg1 {
  width: 100%;
  background: url("../../assets/mobile/product/bg.png") no-repeat;
  height: 48rem;
  background-size: 100% 100%;
  font-size: 0.93rem;
  line-height: 1.375rem;
  font-weight: 400;
  color: #4e4d5e;
  .box {
    width: 83%;
    margin: 2rem auto 0;
    text-align: justify;
    letter-spacing: 1px;
    span {
      display: block;
    }
    .tips {
      width: 2px;
      height: 11px;
      background: #ff3162;
      display: inline-block;
      margin-right: 4px;
    }
    .title {
      font-size: 0.8125rem;

      font-weight: 500;
      color: #4e4d5e;
      margin-top: 1.625rem;
      display: flex;
      align-items: center;
    }
    .text {
    }
  }
  .chart {
    height: 13.75rem;
    position: relative;
    font-size: 0.6875rem;
    font-family: opposm, sans-serif;
    img {
      width: 90%;
      height: 100%;
      position: absolute;
      left: -6.7%;
      top: 0;
    }
    .time1 {
      position: absolute;
      left: 74%;
      top: 11%;
    }
    .time2 {
      position: absolute;
      left: 74%;
      top: 23.5%;
    }
    .time3 {
      position: absolute;
      left: 74%;
      bottom: 39%;
    }
    .time4 {
      position: absolute;
      left: 74%;
      bottom: 17.5%;
    }
  }
  .chartTab {
    width: 92%;
    height: 16rem;
    text-align: center;
    margin: 1rem auto 0;

    position: relative;
    span {
      display: block;
    }
    .msg1 {
      font-size: 0.82rem;

      font-weight: bold;
      color: #4e4d5e;
      position: absolute;
      top: 4.5%;
      left: 37%;
      font-family: opposm, sans-serif;
    }
    .msg2 {
      font-size: 0.82rem;
      font-weight: bold;
      color: #4e4d5e;

      position: absolute;
      top: 4.5%;
      left: 70.5%;
      font-family: opposm, sans-serif;
    }
    .msg3 {
      font-size: 0.82rem;
      font-weight: bold;
      color: #4e4d5e;
      position: absolute;
      top: 27%;
      left: 11%;
      font-family: opposm, sans-serif;
    }
    .msg4 {
      font-size: 0.5625rem;

      font-weight: 400;
      color: #333333;
      position: absolute;
      top: 27%;
      left: 33%;
    }
    .msg5 {
      font-size: 0.5625rem;

      font-weight: 400;
      color: #333333;
      position: absolute;
      top: 27%;
      left: 63.9%;
    }

    .msg6 {
      font-size: 0.82rem;
      font-weight: bold;
      color: #ffffff;
      position: absolute;
      top: 50.5%;
      left: 11%;
      font-family: opposm, sans-serif;
    }
    .msg7 {
      font-size: 0.5625rem;

      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: 46.5%;
      left: 34.5%;
    }
    .msg8 {
      text-align: center;
      font-size: 0.5625rem;

      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: 46.5%;
      left: 66.5%;
    }
    .msg9 {
      font-size: 0.82rem;
      font-weight: bold;
      color: #ffffff;
      position: absolute;
      top: 73.5%;
      left: 7%;
      font-family: opposm, sans-serif;
    }
    .msg10 {
      position: absolute;
      font-size: 0.5625rem;

      text-align: center;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: 69.5%;
      left: 33%;
    }
    .msg11 {
      text-align: center;
      position: absolute;
      font-size: 0.5625rem;

      text-align: center;
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      top: 73.5%;
      left: 69%;
    }
  }
}
.types {
  width: 100%;
  height: 31rem;

  position: relative;
  background-size: 100% 100%;
  .bgType {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .typeTitle {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 1.875rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: #ffffff;
    letter-spacing: 1px;
    font-family: opposm, sans-serif;
  }
  // img {
  //   width: 45rem;
  //   height: 26.75rem;
  // }
  .redbox {
    display: flex;
    margin-bottom: 1rem;
  }
  .redbox:last-child {
    margin-bottom: 0rem !important;
  }
  .red {
    // display: inline-block;
    width: 0.4375rem !important;
    height: 0.4375rem !important;

    margin-top: 0.45rem;
    margin-right: 0.4rem;
  }
  .typeName {
    letter-spacing: 1px;
    font-size: 0.92rem;
    font-weight: bold;

    color: #ffffff;
    line-height: 1.4rem;
  }
  .typeText {
    font-size: 0.75rem;
    letter-spacing: 2px;

    font-weight: 400;
    color: #ffffff;
  }
  .typeBox {
    position: absolute;
    top: 6rem;
    width: 82%;

    // justify-content: space-between;
    left: 50%;
    transform: translate(-50%, 0);
    .bolds {
      font-weight: bold;
    }
    .typeList {
      display: flex;

      justify-content: space-between;
      font-size: 0.875rem;

      font-weight: 400;
      color: #ffffff;

      text-align: center;

      letter-spacing: 1px;
      position: relative;
      .allLine {
        position: absolute;
        bottom: -8px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 104%;
        height: 1px;
        background-color: #fff;
      }
    }

    .activeType {
      width: 21.25rem;
      height: 3.75rem;
      // background: rgba(0, 0, 0, 0.3);
      font-size: 1.5625rem;

      background-size: 100% 100%;
      color: #ffffff;
      line-height: 3.75rem;
      text-align: center;
      font-family: opposm, sans-serif;
    }
  }
  .btn {
    text-align: center;
    width: 7.5rem;
    height: 2.25rem;
    line-height: 2.25rem;
    background: #ffffff;
    font-size: 0.9375rem;

    font-weight: 400;
    color: #1f10e0;
    border-radius: 3px;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: opposm, sans-serif;
    letter-spacing: 2px;
  }
  .btn:hover {
    background-color: #eeeeff;
  }
}
.bg2 {
  width: 100%;
  height: 30rem;
  // background: url("../assets/product/bg2.png") no-repeat;
  // background-size: cover;
  position: relative;
  // background-size: 100% auto;
  // display: flex;
  // justify-content: center;
  .bgTitle {
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    font-weight: 550;
    color: #3b3b72;
    margin-top: 2.5rem;
    letter-spacing: 2px;
    font-family: opposm, sans-serif;
  }
  .itemList {
    width: 94%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 5rem;
    img {
      width: 3.75rem;
      height: 6rem;
    }
  }

  .itemIcons {
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
    width: 30%;
    .name {
      margin: -0.6rem auto 0;

      border-bottom: 1px solid #3b3b72;
      letter-spacing: 2px;
      font-family: opposm, sans-serif;
      color: #4e4d5e;
      font-size: 0.9rem;
      font-weight: 550;
      padding-bottom: 0.3rem;
    }
    .text {
      margin-top: 0.3rem;

      font-family: opposr, sans-serif;
      color: #4e4d5e;
      font-size: 11px;
    }
    .text1 {
      margin-top: 0.1rem;

      font-family: opposr, sans-serif;
      color: #4e4d5e;
      font-size: 11px;
    }
  }
  .short {
    width: 0.3125rem;
  }
}


.AutoShow {
  padding:0px 2rem;
  width: calc(100% - 4rem);
  height: 26.6875rem;
  background: url("../../assets/mobile/product/showBg.png") no-repeat;
  background-size: cover;
  .AutoSlidelist {
     position: relative;
     margin: 0px auto;
     width: 100%;
    .shadow {
        position:absolute;
        width: 3.125rem;
        height: 100%;
        z-index: 999;
        top: 0%;
        left: 0px;
        background: linear-gradient(to right, rgb(255,255,255) ,rgba(255,255,255,0));
    }   
    .shadow2 {
        position:absolute;
        width:3.125rem;
        height:100%;
        z-index: 999;
        top: 0px;
        right: 0px;
        background: linear-gradient(to left, rgb(245,248,255) , rgba(245,248,255,0));
    }
  }
  .titles {
    padding-top: 1.9375rem;
    padding-bottom: 1.4375rem ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto ;
    width: 100%;
    height: 1.4375rem;
    font-size: 1.5rem;
    font-family: opposm;
    font-weight: 500;
    color: #3B3B72;
  }
  .text-box {
    margin:  1.125rem auto 0px;
    width: 100%;
    font-size: .8rem;
    font-family: opposm;
    font-weight: 500;
    color: #3B3B72;
    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: .2rem;
      &:nth-child(3) {
        margin-bottom: 0px;
      }
    }
  }
  .endText {
    text-align: center;
    margin: 1rem auto 0px;
    width: 12.475rem;
    height: 1.0625rem;
    line-height: 0.6rem;
    font-size: 1.1rem;
    font-family: opposb;
    color: #1F10E0;
    border-bottom: .125rem solid red;
  }
}



.forRecord {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.3125rem;
  background: #3A1FFB;
  font-size:0.5rem;
  color: #ffffff;
}
</style>